.payment__widget-wrapper {
  position: relative;
  background: #ffffff;
  border: 1px solid #edeff1;
  box-sizing: border-box;
  border-radius: 5px;
  max-height: 27rem;
  max-width: 362px;
}

.default_payment__widget-wrapper {
  position: relative;
  background: #ffffff;
  border: 1px solid #edeff1;
  box-sizing: border-box;
  border-radius: 5px;
  max-height: 7rem;
  max-width: 362px;
  display: flex;
  justify-content: flex-start;
}

.payment__widget-container {
  overflow: auto;
}

.payment__widget-footer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background-color: #fcfcfc;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.14), 0px 8px 10px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 0px 0px 5px 5px;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  border-top: 1px solid #bdbdbd;
}

.payment__widget-content_wrapper {
  display: grid;
  grid-gap: 7px;
  padding-left: 1rem;
  padding-right: 1rem;
}

.payment__widget-transaction_history_wrapper {
  margin-top: 10px;
  display: grid;
  grid-template-rows: 1fr;
  grid-gap: 10px;
  padding-left: 1rem;
  padding-right: 1rem;
}

.payment__widget-footer_button_wrapper {
  margin-right: 0px !important;
  text-align: center;
  width: 100%;
}

.payment__widget-footer .bp3-tab-indicator {
  background-color: #6200ee !important;
  color: #ff0000 !important;
  top: -3.5rem !important;
  height: 5px !important;
  width: 50px !important;
  margin: auto !important;
}

.payment__widget-footer .bp3-tab[aria-selected="true"] .cls-1 {
  fill: #6200ee !important;
}

.payment__widget-footer *:focus {
  outline: none;
}

.payment__widget-header {
  height: 4rem;
  background-color: #edeff1;
  border: 1px solid #edeff1;
  border-bottom: 1px solid #999;
  display: flex;
  align-items: center;
  padding: 0.5rem;
}

.disbursement__panel-wallet_indicator--active .disburse-1 {
  fill: #ffffff !important;
}

.disbursement__panel-wallet_indicator--active .disburse-2 {
  fill: #6200ee !important;
}

.wallet__selector_input-wrapper_indicator--active .disburse-1 {
  fill: #6200ee !important;
}

.wallet__selector_input-wrapper_indicator--active .disburse-2 {
  fill: #ffffff !important;
}

.payment__widget-header--title {
  width: 80%;
  font-weight: 600;
  text-align: center;
  font-size: 16px;
}

.payment__widget-footer_button {
  height: 100%;
  text-transform: capitalize;
  color: #ffffff !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  cursor: pointer;
}

.disbursement__details-button {
  background-color: #4cc767 !important;
  color: #ffffff !important;
  border-radius: 7px;
  padding: 15px !important;
}

.success {
  background: #4cc767 !important;
}

.disbursement__panel-section_wrapper .bp3-button {
  display: block;

}

.disbursement__details-button .bp3-button-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 1.25px;
  text-transform: capitalize;
  color: #ffffff;
}

.disbursement__panel-section_wrapper {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  gap: 10px;
  width: 100%;
}

.disbursement__panel-title {
  font-family: "Roboto";
  text-transform: uppercase;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #767676;
}

.disbursement__panel-title-button {
  display: inline-block;
  justify-content: space-between;
  background-color: #ffffff;
}

.disbursement__panel-wallet-select .bp3-popover-target {
  display: inline-block;
  width: 100%;
}

.disbursement_interval-wrapper {
  display: flex;
  align-items: center;
}

.payment__widget-activity_list-list-wrapper {
  overflow: auto;
  max-height: 19rem;
}

.payment__widget-activity_list-list_item {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #182026;
}

.payment__widget-activity_list-wrapper {
  display: flex;
  align-items: center;
}

.payment__widget-activity_list-wallet_group {
  padding-right: 10px;
  padding-left: 10px;
  font-weight: 700;
}

.payment__widget-activity_list-wallet_group .group-text {
  font-weight: 500;
  font-size: 14px;
  color: #999;
}

.disbursement__panel-wallet_indicator_wrapper {
  justify-content: space-between;
  padding-left: 10px;
  
}

.disbursement__panel-wallet_indicator_wrapper--active {
  background-color: #6200ee;
}

.payment__widget-activity_sent-transaction {
  color: #6200ee;
  font-weight: 600;
  text-align: right;
}

.payment__widget-activity_received-transaction {
  color: #4cc767;
  font-weight: 600;
  text-align: right;
}

.amount-text {
  padding: 0;
  margin: 0;
}

.transaction-text {
  color: #999;
  font-weight: 500;
  font-size: 14px;
  padding: 0;
  margin: 0;
}

.disbursement__panel-wallet_indicator_wrapper--inner {
  display: flex;
  justify-content: space-between;
  /* padding-left: 10px; */
}

.disbursement__panel-wallet_indicator_wrapper--indicator {
  display: flex;
  width: 200px;
  justify-content: space-between;
  padding-right: 10px;
}

.disbursement__panel-wallet_info_wrapper {
  display: flex;
  justify-content: space-between;
}

.payment_summary {
  background-color: #f8fafc;
  padding-right: 25px;
  padding-left: 25px;
}

.payment_summary__disbursement_details_wrapper {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  padding-top: 10px;
}

.target-group__selector-wrapper {
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  overflow: auto;
  max-height: 20.5rem;
  width: 100%;
}



.wallet__selector_input-wrapper {
  padding-top: 12px;
  padding-bottom: 12px;
  border: 1px solid #edeff1;
  margin-bottom: 10px;
  border-radius: 6px;
}

.add__group_button {
  padding-top: 12px;
  padding-bottom: 12px;
  border: 1px solid #edeff1;
  margin-bottom: 10px;
  border-radius: 6px;
  height: 70px;
  font-size: 18px;
  font-weight: bold;
  width: 300px;
}

.wallet__selector-group-name {
  margin-bottom: 0px;
  font-size: 15px;
  font-weight: 600;
  margin-left: 5px;
}

.wallet__selector-wallet-count {
  color: #999;
  font-size: 15px;
  margin-left: 5px;
}

.payment__widget-master-wallet_wrapper {
  margin-top: 10px;
  width: 100%;
}

.master-wallet__panel-title,
.master-wallet__balance,
.transaction-history__panel-title {
  margin: 0;
  padding: 0;
}

.master-wallet__balance {
  font-family: "Roboto Mono";
  letter-spacing: 3px;
  color: #6200ee;
  padding-top: 5px;
  padding-bottom: 5px;
}

.master-wallet__balance-description {
  font-style: normal;
  font-size: 12px;
  color: #757575;
}

.payment__widget-wallet_wrapper {
  margin-top: 6rem;
}

.payment__widget-container--padding {
  padding-left: 1rem;
  padding-right: 1rem;
}

.wallet-panel_button,
.disbursement__panel-wallet_indicator_wrapper {
  border: 1px solid #e3dddd;
  border-radius: 10px;
  color: white;
  padding: 5px 5px 10px 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  column-gap: 10px;
}

.wallet-panel_disburse-fund {
  background-color: #6200ee;
  margin-top: 6px;
}

.hide {
  display: none;
}

.wallet-panel_manage-wallet {
  /* TODO: set -> background-color: #151516 when implemented  */
  background-color: transparent;
  pointer-events: none;
}

.wallet-panel_disburse-fund .cls-1,
.wallet-panel_manage-wallet .cls-1 {
  fill: #ffffff;
}

.no-bottom-margin {
  margin-bottom: 0;
}

.disbursement__panel-wallet_indicator--inner {
  display: flex;
  align-items: flex-start;
}

.disbursement__panel-wallet_indicator--text {
  color: #999 !important;
  font-weight: 600;
}

.disbursement__panel-wallet_indicator--active-text {
  color: #ffffff !important;
  font-weight: 600;
}

.payment__disbursement__wallet-summary {
  border: 1px solid #e3dddd;
  border-radius: 10px;
  color: grey;
  padding: 15px 20px;
  display: flex;
  grid-column-gap: 15px;
  column-gap: 15px;
  align-items: center;
  height: 70px;
  margin-top: 5px;
}

.payment__disbursement-review--text {
  font-weight: 700;
  color: #000;
}

.payment__disbursement-review--subtext {
  display: flex;
  justify-content: space-between;
}

.padding-5 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.summary-disbursement {
  display: flex;
  border-bottom: 1px solid #edeff1;
  padding: 15px 15px;
}

.grey-text {
  color: #999;
}

.dark-text {
  color: #030303 !important;
}

.font-weight-600 {
  font-weight: 600;
}

.font-size-15 {
  font-size: 15px;
}

.margin-horizontal-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.mt-6 {
  margin-top: 6px;
}

.circle {
  height: 257px;
  width: 257px;
  background-color: #580DE5;
  border-radius: 50%;
  display: inline-block;
}

.default_wallet_container {
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly
}

.master_wallet_wrapper {
   margin-Left: 15px;
   margin-bottom: 30px
}

.master_wallet_title {
  margin-top: 30px;
  margin-left: 15px;
  margin-bottom: 30px
}

.master_wallet_svg_circle {
  background-color: #580DE5;
  height: 140px;
  width: 140px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center
}

.master_wallet_info_container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 30px;
}

.master_wallet_info_up {
  font-weight: bold;
  font-size: 15px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px
}

.master_wallet_info_down {
  line-height: 15px;
  font-size: 12px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column
}

.master_wallet_info_no_project {
  font-weight: 600;
  font-size: 15px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column
}
